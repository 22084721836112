body {
  color: #ffffff;
  font-family: "Playfair Display", serif !important;
  font-size: 1.05rem !important;
  font-style: italic;
  background-image: url("./assets/images/bkg-op40-sm.webp");
  background-attachment: fixed;
  background-position: center;
  background-origin: border-box;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.h6,
h6 {
  font-size: inherit !important;
}
a {
  text-decoration: none !important;
}
a:hover {
  color: rgba(135, 22, 62, 0.7) !important;
}
ul.seed {
  list-style-image: url("./assets/images/seed.webp") !important;
  text-align: justify;
}
ul.bound {
  list-style-position: inside;
}
.fixed-center {
  position: fixed;
  top: 30%;
  left: 50%;
}
.center-left {
  top: 50%;
  left: 2%;
}
.center-right {
  top: 50%;
  right: 2%;
}
.font-large {
  font-size: 2.5rem;
}
.font-style-normal {
  font-style: normal;
}

.text-red {
  color: #87163e;
}
.text-red-light {
  color: rgba(135, 22, 62, 0.7);
}
.text-dark-red {
  color: rgba(83, 16, 39, 0.8);
}
.text-coffee {
  color: #3d2500;
}
.text-yellow {
  color: #daba54;
}
.text-pink {
  color: rgb(228, 204, 201) !important;
}
.text-pink:hover {
  color: rgb(211, 167, 161) !important;
}
.text-transparent {
  color: transparent;
}
.bg-dark-red {
  background-color: rgba(83, 16, 39, 0.8) !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-opacity-dark {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.bg-pink-grad {
  background-image: radial-gradient(
    circle,
    rgba(217, 184, 180, 0.09),
    rgba(234, 212, 210, 0.5),
    rgba(255, 255, 255, 0.05)
  );
}
.bg-pink {
  background-color: rgba(252, 244, 243, 0.4);
}
.bg-dark-pink {
  background-color: rgba(252, 244, 243, 1) !important;
}
.bg-ash-pink {
  background-color: rgb(214, 202, 200) !important;
}
.bg-image {
  background-attachment: local;
  background-position: center;
  background-origin: border-box;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bg-image-nav {
  background-attachment: fixed;
  background-position: center;
  background-origin: border-box;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-image: url("./assets/images/bkg-lessSat-sm.webp");
}
.bg-image-nav.opacity {
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.6);
}

.bg-image.hover {
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.1);
  color: #ffffff !important;
  letter-spacing: 0.1em;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 3px 8px;
  text-shadow: 10px 10px 25px #000000, -10px 10px 25px #000000,
    -10px -10px 25px #000000, 10px -10px 25px #000000;
}
.bg-image.hover:hover {
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.6);
  color: #87163e !important;
  text-shadow: none;
}

.hover:hover .black-n-white-hover {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.display-on-hover {
  color: transparent !important;
}
.hover:hover .display-on-hover {
  color: #87163e !important;
}

.navbar-red .nav-link {
  color: rgba(83, 16, 39, 0.8);
}
.navbar-red .nav-link:hover {
  color: rgba(135, 22, 62, 0.7) !important;
}
.navbar-red .navbar-brand {
  color: rgba(135, 22, 62, 0.9);
}
.navbar-red .nav-link.active {
  color: rgba(135, 22, 62, 1);
  /* text-decoration: underline !important; */
  border-bottom: solid 1px #87163e;
}

.btn.btn-outline-red {
  color: rgba(83, 16, 39, 0.6);
  border-color: rgba(83, 16, 39, 0.5);
}
.btn.btn-outline-red:hover {
  color: rgba(135, 22, 62, 0.9);
  border-color: rgba(135, 22, 62, 0.2);
}
.btn.btn-red {
  color: #ffffff;
  border-color: rgba(135, 22, 62, 0.9);
  background-color: rgba(135, 22, 62, 0.9);
}
.btn.btn-red:hover {
  color: #ffffff;
  border-color: #87163e;
  background-color: #87163e;
}

textarea:focus,
input[type="text"]:focus,
input[type="email"]:focus,
video:focus {
  outline: none !important;
  border-color: rgba(135, 22, 62, 0.5) !important;
  box-shadow: 0 1px 1px rgba(255, 255, 255, 0.075),
    0 0 8px rgba(135, 22, 62, 0.6) !important;
}

.max-w-200 {
  max-width: 200px;
}
.max-h-50 {
  max-height: 50vh;
}
.max-h-150 {
  max-height: 150px;
}
.min-h-150 {
  min-height: 150px;
}
.min-h-50 {
  min-height: 50vh;
}
.h-50-vh {
  height: 50vh;
}
.h-80-vh {
  height: 80vh;
}
.h-30-vh {
  height: 30vh;
}
.page {
  margin: 230px auto 16px auto;
  min-height: 80vh;
}
.page.home {
  margin-top: 0;
  min-height: 100vh;
}

.underline {
  position: relative;
}
.underline.top:before {
  background: -moz-linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  background: -webkit-linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  background: -ms-linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  background: linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  border-radius: 0.2em;
  top: 0;
  content: "";
  height: 0.05em;
  position: absolute;
  right: 0;
  width: 100%;
}
.underline.bottom:after {
  background: -moz-linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  background: -webkit-linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  background: -ms-linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  background: linear-gradient(to right, #edeae9, #87163e, #f6f4f3);
  border-radius: 0.2em;
  bottom: 0;
  content: "";
  height: 0.05em;
  position: absolute;
  right: 0;
  width: 100%;
}

.scroll::-webkit-scrollbar,
.overflow-auto::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.scroll::-webkit-scrollbar-track,
.overflow-auto::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(234, 212, 210, 0.8);
  border-radius: 8px;
}
.scroll::-webkit-scrollbar-thumb,
.overflow-auto::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(234, 212, 210, 1);
}
.white.scroll::-webkit-scrollbar-track,
.white.overflow-auto::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.4);
  border-radius: 8px;
}
.white.scroll::-webkit-scrollbar-thumb,
.white.overflow-auto::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.8);
}

.logo {
  max-width: 250px;
  width: 100%;
  height: auto;
}
.logo.home {
  max-width: 300px;
  width: 100%;
  height: auto;
}
.logo.side {
  width: 30%;
  height: auto;
}
.logo-group {
  position: absolute;
  left: 0;
  top: 1em;
  right: auto;
}
.logo-group a img {
  width: 8%;
  height: auto;
  margin-right: 8px;
}
#topNavBtn {
  border: none;
  position: absolute;
  right: 0.2em;
  top: 0.2em;
}

#topNavBtn:focus,
a:focus,
button:focus,
.custom-control-label::before {
  outline: 0 !important;
  box-shadow: none !important;
}

.small-nav {
  display: none;
}
.big-nav {
  display: block;
}
.additional-nav {
  position: absolute;
  right: 0.5em;
  top: 1em;
  text-align: right;
}
.bottom-0{
 bottom: 0;
}
.right-0 {
  right: 0;
}
.social-btns {
  margin-right: 12px;
}
.slogan {
  position: absolute;
  text-align: right;
  font-size: 80%;
  font-style: italic;
  line-height: 1em;
  bottom: 12%;
  left: 29%;
  width: 18%;
}
.home .slogan {
  position: absolute;
  text-align: right;
  font-size: 90%;
  line-height: 1em;
  bottom: 14%;
  left: 0%;
  width: 50%;
}
.modal.show {
  -moz-transition: opacity 160ms ease-out;
  -webkit-transition: opacity 160ms ease-out;
  -ms-transition: opacity 160ms ease-out;
  -o-transition: opacity 160ms ease-out;
  transition: opacity 160ms ease-out;
}
.modal-content-slide-down {
  animation-name: animatetop;
  animation-duration: 0.1s;
}
.modal-large {
  max-width: 85% !important;
}
.tooltip-parent {
  position: relative;
}
.tooltip-parent .tooltip-on-hover {
  display: none;
  position: absolute;
  left: 0;
  z-index: 100000;
  color: rgb(119, 82, 93);
  background-color: #f5f1f3;
  border: 1px solid #e6dede;
  border-radius: 0.5em;
  padding: 0 16px;
}
.tooltip-parent .tooltip-on-hover.right-0 {
  left: auto;
  right: 0;
}
.tooltip-parent:hover .tooltip-on-hover {
  display: block;
}
.awaiting {
  pointer-events: none;
  opacity: 0.3;
}

.developed-by {
  position: absolute;
  right: 8px;
  bottom: 8px;
}

.left-slide {
  animation: 2.5s ease-out 0s 1 slideInFromLeft;
}
.right-slide {
  animation: 2.5s ease-out 0s 1 slideInFromRight;
}
.fade-in {
  opacity: 0;
  animation: 1s linear 1 fade-in;
  animation-fill-mode: forwards;
}
.fade-in.infinite {
  opacity: 0;
  animation: 1s linear 1 infinite alternate fade-in;
  animation-fill-mode: forwards;
}
.fade-out {
  opacity: 1;
  animation: 1s linear 1 fade-out;
  animation-fill-mode: forwards;
}
.blink {
  animation: blinker 2.5s linear infinite;
}
.blink-on-hover:hover {
  animation: blinker 2.5s linear infinite;
}
@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
@media (min-height: 1000px) {
  .bg-image.height {
    height: 75vh;
  }
  .bg-image.h-albums {
    height: 53vh;
  }
}
@media (max-height: 1000px) {
  .bg-image.height {
    height: 72vh;
  }
  .bg-image.h-albums {
    height: 51vh;
  }
}
@media (max-height: 900px) {
  .bg-image.height {
    height: 68vh;
  }
}
@media (max-height: 800px) {
  .bg-image.height {
    height: 65vh;
  }
}
@media (min-width: 1500px) {
  .container,
  .container-md {
    max-width: 80% !important;
  }
}
@media (min-width: 992px) {
  .w-lg-75 {
    width: 75% !important;
  }
  .modal-large {
    margin-left: auto;
    margin-right: auto;
    max-width: 90% !important;
  }
}
@media (min-width: 768px) {
  .fixed-top-lg {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .container {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }  
  .bg-image.bottom-pos {
    background-position: bottom;
  }
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-40%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes slideInFromRight {
    0% {
      transform: translateX(40%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes blinker {
    50% {
      opacity: 0.25;
    }
  }
}
@media (max-width: 1499px) {
  .page {
    margin: 180px auto 16px auto;
    min-height: 85vh;
  }
  .logo {
    max-width: 220px;
  }
  .slogan {
    font-size: 70%;
    bottom: 12%;
    left: 27%;
    width: 20%;
  }
}
@media (max-width: 1200px) {
  .slogan {
    bottom: 12%;
    left: 21%;
    width: 25%;
  }
  .home .slogan {
    bottom: 14%;
    left: 0%;
    width: 59%;
  }
}
@media (max-width: 992px) {
  .logo {
    max-width: 170px;
  }
  .slogan {
    bottom: 12%;
    left: 4%;
    width: 40%;
  }
  .home .slogan {
    font-size: 70%;
    bottom: 14%;
    left: 0%;
    width: 65%;
  }
}

@media (max-width: 767px) {
  .page {
    margin: 20px auto 16px auto;
  }
  .modal-large {
    max-width: calc(100% - 16px) !important;
  }
  .navbar {
    padding: 0;
  }
  /* .logo-nav {
    vertical-align: center;
    min-height: 140px;
  } */
  .logo,
  .logo.home {
    max-width: 150px;
    width: 100%;
    height: auto;
  }
  .logo-group {
    left: 1em;
    top: 0.6em;
  }
  .additional-nav {
    right: 3.7em;
    top: 0.6em;
  }
  .slogan {
    bottom: 15%;
    right: 2em;
    width: 50%;
  }
  .home .slogan {
    font-size: 60%;
    bottom: 14%;
    left: -7%;
    width: 40%;
  }
  .small-nav {
    display: block;
    z-index: 100000;
  }
  .big-nav {
    display: none;
  }
  .navbar-red.navbar.main .nav-link.active {
    color: rgb(158, 12, 63);
    border-bottom: none;
  }
  #topNav {
    background-color: #fcf8f7;
    border-top: solid 1px rgba(255, 255, 255, 0.5);
    border-bottom: solid 1px rgba(255, 255, 255, 0.5);
    position: absolute;
    display: none;
    width: 100%;
    top: 108px;
    left: 0;
    z-index: 1000;
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 1.5em;
    height: 0.25em;
    line-height: 0.25em;
    background-color: #87163e;
    margin: 0.25em 0;
    -moz-transition: 0.4s;
    -webkit-transition: 0.4s;
    -ms-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
  }

  .change .bar1 {
    -moz-transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
    -webkit-transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
    -ms-transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
    -o-transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
    transform: rotate(-45deg) translate(-0.34375em, 0.3125em);
  }

  .change .bar2 {
    opacity: 0;
  }

  .change .bar3 {
    -moz-transform: rotate(45deg) translate(-0.375em, -0.375em);
    -webkit-transform: rotate(45deg) translate(-0.375em, -0.375em);
    -ms-transform: rotate(45deg) translate(-0.375em, -0.375em);
    -o-transform: rotate(45deg) translate(-0.375em, -0.375em);
    transform: rotate(45deg) translate(-0.375em, -0.375em);
  }
  .fade-in {
    opacity: 1;
  }
  .developed-by {
    position: relative;
    margin-top: 16px;
  }
  .bg-image.top-pos {
    background-position: 50% 10%;
  }
}
@media (max-width: 575px) {
  .bg-image.height {
    height: 50vh;
  }
  .social-btns {
    margin-right: 4px;
  }
  .slogan {
    flex: none;
  }
  .logo-group {
    left: 0.2em;
    top: 0.5em;
  }
  .logo-group a img {
    width: 11%;
  }
}

@media (max-width: 420px) {
  .social-btns {
    display: none;
  }
  .bg-image.height {
    height: 30vh;
  }
}
@media (max-width: 300px) {
  .additional-nav {
    right: 0.2em;
    top: 0.5em;
  }
  .logo,
  .logo.home {
    max-width: 170px;
  }
}
@media (max-width: 210px) {
  #topNav {
    top: 100px;
  }
}
